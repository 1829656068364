import { useState } from "react";
import { Link } from "react-router-dom";

const LogInForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormData({
      email: "",
      password: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col items-center h-full gap-5 xl:gap-6">
      <h1 className=" font-poppins uppercase text-green-1 font-semibold text-3xl xl:text-4xl">
        Log in
      </h1>
      <p className="max-w-[520px] w-full text-center text-xs md:text-base">
        Lorem ipsum dolor sit amet consectetur. Adipiscing dignissim aliquam
        gravida feugiat. Eu vestibulum elementum leo eu in feugiat.
      </p>
      <form
        onSubmit={handleSubmit}
        className="max-w-[438px] w-full h-full flex flex-col items-center gap-14"
      >
        <div className="w-full flex flex-col items-center justify-between gap-4">
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">E-mail</span>
            <input
              className="bg-gray-1 text-xs border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1  md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="email"
              name="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">Password</span>
            <input
              className="bg-gray-1 text-xs border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1  md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="w-full flex flex-col items-center gap-1.5">
          <button
            className="w-full bg-green-1 text-white-1 p-3 rounded-lg text-sm font-medium transition-all duration-300 hover:bg-green-2 lg:text-base"
            type="submit"
          >
            Log In With Cargonauts
          </button>
          <div className="flex items-center gap-1 p-2">
            <span className="font-medium text-xs md:text-sm">
              Don't have an account yet?
            </span>
            <Link
              className="font-medium text-xs text-green-1 md:text-sm transition-all duration-300 hover:text-green-2"
              to="/"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LogInForm;
