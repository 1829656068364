import React, { useState, useEffect } from "react";

const Switcher = ({ option1, option2, setFormData }) => {
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      is_business_user: selectedOption,
    }));
  }, [selectedOption, setFormData]);

  const handleSwitch = () => {
    setSelectedOption((prevOption) => (prevOption === 0 ? 1 : 0));
  };

  return (
    <div
      className="relative max-w-96 h-11 w-full border border-gray-6 rounded-full overflow-hidden cursor-pointer py-0.5 px-1 sm:h-12"
      onClick={handleSwitch}
    >
      <div
        className={`absolute w-1/2 top-0.5 bottom-0.5 bg-green-3 shadow-lg shadow-green-3/40 rounded-full transition-transform duration-300 ease-in-out ${
          selectedOption === 0
            ? "transform translate-x-0"
            : "transform translate-x-[calc(100%-8px)]"
        }`}
      ></div>
      <div className="relative grid grid-cols-2 w-full h-full z-10">
        <span
          className={`flex justify-center items-center font-medium text-xs sm:text-base ${
            selectedOption === 0 ? "text-white" : "text-black-1"
          }`}
        >
          {option1}
        </span>
        <span
          className={`flex justify-center items-center font-medium text-xs sm:text-base ${
            selectedOption === 1 ? "text-white" : "text-black-1"
          }`}
        >
          {option2}
        </span>
      </div>
    </div>
  );
};

export default Switcher;
