import ScreenSide from "../auth/ScreenSide";
import FormLayout from "../auth/FormLayout";

const AuthLayout = ({ children }) => {
  return (
    <div className="min-h-screen h-full grid grid-cols-1 md:grid-cols-3">
      <div className="md:col-span-1">
        <ScreenSide />
      </div>
      <div className="md:col-span-2">
        <FormLayout>{children}</FormLayout>
      </div>
    </div>
  );
};

export default AuthLayout;
