const FormLayout = ({ children }) => {
  return (
    <div className="h-full overflow-y-scroll no-scrollbar w-full">
      <div className="w-full h-full px-6 py-14 md:px-8 md:py-7 lg:px-14 lg:py-12 xl:px-24 xl:py-20">
        {children}
      </div>
    </div>
  );
};

export default FormLayout;
