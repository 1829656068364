import axios from "axios";

const register = async (userData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_URI}/register`,
    userData
  );
  return response.data;
};

const getUserTypes = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_URI}/user/get_user_types`
  );

  return response.data;
};

const authService = { register, getUserTypes };
export default authService;
