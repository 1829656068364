import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switcher from "./Switcher";
import SelectUserType from "./SelectUserType";
import { useDispatch } from "react-redux";
import { registerUser } from "../../features/auth/authSlice";

const RegisterForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    user_type_id: "",
    is_business_user: "",
    first_name_en: "",
    first_name_ge: "",
    last_name_en: "",
    last_name_ge: "",
    company_name_en: "",
    company_name_ge: "",
    email: "",
    password: "",
    password_confirmation: "",
    "terms_and_conditions[0][id]": "",
  });
  const [validationErrors, setValidationErrors] = useState({
    user_type_id: [],
    first_name_en: [],
    first_name_ge: [],
    last_name_en: [],
    last_name_ge: [],
    company_name_en: [],
    company_name_ge: [],
    email: [],
    password: [],
    password_confirmation: [],
    "terms_and_conditions[0][id]": [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };
    if (Object.values(formData).some((value) => value)) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [formData]);

  useEffect(() => {
    if (isSubmitting) {
      const isValid = Object.values(validationErrors).every(
        (errors) => errors.length === 0
      );

      if (isValid) {
        const dataToSend = {
          user_type_id: formData.user_type_id,
          is_business_user: formData.is_business_user,
          first_name_en: formData.first_name_en,
          first_name_ge: formData.first_name_ge,
          last_name_en: formData.last_name_en,
          last_name_ge: formData.last_name_ge,
          company_name_en: formData.company_name_en,
          company_name_ge: formData.company_name_ge,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.password_confirmation,
          terms_and_conditions: [
            {
              id: formData["terms_and_conditions[0][id]"],
            },
          ],
        };

        dispatch(registerUser(dataToSend));
        setFormData({
          user_type_id: "",
          is_business_user: "",
          first_name_en: "",
          first_name_ge: "",
          last_name_en: "",
          last_name_ge: "",
          company_name_en: "",
          company_name_ge: "",
          email: "",
          password: "",
          password_confirmation: "",
          "terms_and_conditions[0][id]": "",
        });
      }

      setIsSubmitting(false);
    }
  }, [validationErrors, isSubmitting, dispatch, formData]);

  const handleChange = (e) => {
    const { name, type, checked } = e.target;

    const newValue = type === "checkbox" ? (checked ? 1 : "") : e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    if (validationErrors[name]) {
      validateFields(name, newValue);
    }
  };

  const validateFields = (name, value) => {
    let errors = [];

    const nameRegex = /^[a-zA-Z\s]+$/;
    const nameGeRegex = /^[ა-ჰ\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = {
      length: /.{8,}/,
      uppercase: /[A-Z]/,
      lowercase: /[a-z]/,
      number: /\d/,
      symbol: /[!@#$%^&*]/,
    };

    switch (name) {
      case "user_type_id":
        if (!value) errors.push("Choose a user type");
        break;
      case "first_name_en":
        if (!value) errors.push("Please enter your full name");
        else {
          if (value.length < 3)
            errors.push("Your name must be at least 3 characters long");
          if (!nameRegex.test(value))
            errors.push("Your name must not contain numbers.");
        }
        break;
      case "first_name_ge":
        if (!value) errors.push("Please enter your full name");
        else {
          if (value.length < 3)
            errors.push("Your name must be at least 3 characters long");
          if (!nameGeRegex.test(value))
            errors.push("Use only Georgian letters");
        }
        break;
      case "last_name_en":
        if (!value) errors.push("Please enter your full lastname");
        else {
          if (value.length < 3)
            errors.push("Your lastname must be at least 3 characters long");
          if (!nameRegex.test(value))
            errors.push("Your lastname must not contain numbers.");
          if (value.includes(" ")) {
            errors.push("Last name should not contain spaces");
          }
        }
        break;
      case "last_name_ge":
        if (!value) errors.push("Please enter your full lastname");
        else {
          if (value.length < 3)
            errors.push("Your lastname must be at least 3 characters long");
          if (!nameGeRegex.test(value))
            errors.push("Use only Georgian letters");
        }
        break;
      case "company_name_en":
        if (!value) errors.push("Please enter your Company name");
        break;
      case "company_name_ge":
        if (!value) errors.push("Please enter your Company name");
        else {
          if (!nameGeRegex.test(value))
            errors.push("Use only Georgian letters");
        }
        break;
      case "email":
        if (!value) errors.push("Please enter your email address");
        else if (!emailRegex.test(value))
          errors.push("Please enter a valid email address, e.g. joe@mail.com");
        break;
      case "password":
        if (!passwordRegex.length.test(value))
          errors.push("At least 8 characters");
        if (!passwordRegex.uppercase.test(value))
          errors.push("At least 1 uppercase letter A");
        if (!passwordRegex.lowercase.test(value))
          errors.push("At least 1 lowercase letter a");
        if (!passwordRegex.number.test(value))
          errors.push("At least 1 number 123");
        if (!passwordRegex.symbol.test(value))
          errors.push("At least 1 Symbol %");
        break;
      case "password_confirmation":
        if (value !== formData.password)
          errors.push("The password you entered does not match");
        break;
      case "terms_and_conditions[0][id]":
        if (!value) errors.push("Agree to the terms and conditions");
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors,
    }));
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fieldsToValidate = Object.keys(formData);
    fieldsToValidate.forEach((field) => {
      validateFields(field, formData[field]);
    });

    setIsSubmitting(true);
  };

  let formFields;
  switch (step) {
    case 1:
      formFields = (
        <>
          <Switcher
            option1="Physical person"
            option2="Legal entity"
            setFormData={setFormData}
          />
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">User type</span>
            <SelectUserType
              setFormData={setFormData}
              setValidationErrors={setValidationErrors}
            />
            {validationErrors.user_type_id.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">Name</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1  md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="text"
              name="first_name_en"
              placeholder="Name"
              value={formData.first_name_en}
              onChange={handleChange}
            />
            {validationErrors.first_name_en.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">სახელი (ქართულად)</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="text"
              name="first_name_ge"
              placeholder="სახელი (ქართულად)"
              value={formData.first_name_ge}
              onChange={handleChange}
            />
            {validationErrors.first_name_ge.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">Last name</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="text"
              name="last_name_en"
              placeholder="Last name"
              value={formData.last_name_en}
              onChange={handleChange}
            />
            {validationErrors.last_name_en.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">გვარი (ქართულად)</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="text"
              name="last_name_ge"
              placeholder="გვარი (ქართულად)"
              value={formData.last_name_ge}
              onChange={handleChange}
            />
            {validationErrors.last_name_ge.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">E-mail</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="email"
              name="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleChange}
            />
            {validationErrors.email.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
        </>
      );
      break;
    case 2:
      formFields = (
        <>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">Company name</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="text"
              name="company_name_en"
              placeholder="Company name"
              value={formData.company_name_en}
              onChange={handleChange}
            />
            {validationErrors.company_name_en.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">კომპანიის სახელი (ქართულად)</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="text"
              name="company_name_ge"
              placeholder="კომპანიის სახელი (ქართულად)"
              value={formData.company_name_ge}
              onChange={handleChange}
            />
            {validationErrors.company_name_ge.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">Password</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {validationErrors.password.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <label className="flex flex-col gap-1.5 w-full">
            <span className="text-xs">Re-type password</span>
            <input
              className="bg-gray-1 text-xs placeholder:text-black-1 border border-gray-2/40 rounded-lg py-2.5 px-3 focus:border-green-1 focus:outline-none focus:shadow-shadow-1 md:py-4 md:px-3 lg:py-5 lg:px-4"
              type="password"
              name="password_confirmation"
              placeholder="Re-type password"
              value={formData.password_confirmation}
              onChange={handleChange}
            />
            {validationErrors.password_confirmation.map((error, index) => (
              <span key={index} className="text-red-500 text-xs">
                {error}
              </span>
            ))}
          </label>
          <div className="flex flex-col gap-1.5 w-full">
            <label className="flex items-center justify-start gap-2">
              <input
                type="checkbox"
                name="terms_and_conditions[0][id]"
                checked={formData["terms_and_conditions[0][id]"] === 1}
                onChange={handleChange}
              />
              <span className="text-xs">
                I have read and agree to the terms and conditions
              </span>
            </label>
            {validationErrors["terms_and_conditions[0][id]"].map(
              (error, index) => (
                <span key={index} className="text-red-500 text-xs">
                  {error}
                </span>
              )
            )}
          </div>
        </>
      );
      break;

    default:
      formFields = null;
  }

  return (
    <div className="flex flex-col items-center h-full gap-5 xl:gap-6">
      <h1 className=" font-poppins uppercase text-green-1 font-semibold text-3xl xl:text-4xl">
        REGISTRATION
      </h1>
      <p className="max-w-[520px] w-full text-center text-xs md:text-base">
        Lorem ipsum dolor sit amet consectetur. Adipiscing dignissim aliquam
        gravida feugiat. Eu vestibulum elementum leo eu in feugiat.
      </p>
      <form
        onSubmit={handleSubmit}
        className="max-w-[438px] w-full h-full flex flex-col items-center justify-between gap-10"
      >
        <div className="w-full flex flex-col items-center justify-between gap-4">
          {formFields}
        </div>

        <div className="w-full flex flex-col items-center gap-1.5">
          {step > 1 && (
            <button
              className="w-full border border-green-1 text-green-1 p-3 rounded-lg text-sm font-medium transition-all duration-300 hover:text-green-2 lg:text-base"
              type="button"
              onClick={prevStep}
            >
              Previous
            </button>
          )}
          {step < 2 && (
            <button
              className="w-full bg-green-1 text-white-1 p-3 rounded-lg text-sm font-medium transition-all duration-300 hover:bg-green-2 lg:text-base"
              type="button"
              onClick={nextStep}
            >
              Next
            </button>
          )}
          {step === 2 && (
            <button
              className="w-full bg-green-1 text-white-1 p-3 rounded-lg text-sm font-medium transition-all duration-300 hover:bg-green-2 lg:text-base"
              type="submit"
            >
              Submit
            </button>
          )}
          <div className="flex items-center gap-1 p-2">
            <span className="font-medium text-xs md:text-sm">
              Already have an account?
            </span>
            <Link
              className="font-medium text-xs text-green-1 md:text-sm transition-all duration-300 hover:text-green-2"
              to="/signin"
            >
              Sign In
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
