import logo from "../../assets/cargonauts.svg";

const ScreenSide = () => {
  return (
    <div className="bg-custom-gradient h-full w-full flex-col justify-center items-center gap-5 hidden md:flex">
      <img
        className="max-w-48 w-full object-contain lg:max-w-60"
        src={logo}
        alt="cargonauts"
      />
      <span className="font-hustle uppercase text-green-1 font-normal tracking-wider leading-10 text-2xl lg:text-4xl">
        CARGONAUTS
      </span>
    </div>
  );
};

export default ScreenSide;
