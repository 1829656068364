import { useState, useEffect, useRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getUserTypes } from "../../features/auth/authSlice";

const SelectUserType = ({ setFormData, setValidationErrors }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { userTypes } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  useEffect(() => {
    dispatch(getUserTypes());
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectChange = (selectedValue) => {
    setSelectedValue(selectedValue);
    setFormData((prev) => ({
      ...prev,
      user_type_id: selectedValue?.id,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      user_type_id: [],
    }));

    setIsOpen(false);
  };

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <div
        className="w-full flex items-center justify-between bg-gray-1 text-black-1 text-xs text-start border border-gray-2/40 rounded-lg py-2.5 px-3 md:py-4 md:px-3 lg:py-5 lg:px-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedValue !== null ? selectedValue.name : "User type"}
        <IoMdArrowDropdown className="w-4 min-w-4 h-4 fill-gray-4" />
      </div>
      {isOpen && (
        <ul className="absolute right-0 -mt-4 bg-white z-50 border border-blue-1/30 rounded-md p-1 flex flex-col gap-1 w-40">
          {userTypes.map((item, index) => (
            <li
              key={index}
              onClick={() => handleSelectChange(item)}
              className="text-xs py-1 px-2 border-b border-black/10 rounded-md cursor-pointer transition-all duration-300 hover:bg-green-3/30 bg-transparent"
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectUserType;
