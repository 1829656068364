import AuthLayout from "../components/auth/AuthLayout";
import LogInForm from "../components/auth/LogInForm";

const LogIn = () => {
  return (
    <AuthLayout>
      <LogInForm />
    </AuthLayout>
  );
};

export default LogIn;
